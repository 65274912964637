import axiosInstance from './interceptors'
import {
  API_V1,
  MEMBERS_API,
  USERS_API,
  VUE_APP_SERVER_BASE_URL,
} from '../utils/constants'

export const updateUser = async (updated_user) => {
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/update`,
    { updated_user }
  )
}

export const changeUserPassword = async (passwords) => {
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/change-password`,
    { passwords }
  )
}
export const getMemberData = async (memberId, member_pipeline_id) => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}${MEMBERS_API}/deals/${memberId}`,
    {
      params: { member_pipeline_id },
    }
  )
  return data
}
export const getMembers = async (payload) => {
  const { pageSize, pageNumber, order, searchTerm } = payload
  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/list?page_number=${pageNumber}`

  if (pageSize !== 'All') url += `&page_size=${pageSize}`
  if (order) url += `&order=${order}`
  if (searchTerm) url += `&search_term=${searchTerm}`

  const {
    data: { data },
  } = await axiosInstance.get(url)

  return data
}
export const getAttachedUsers = async (payload) => {
  const { pageSize, pageNumber, order, searchTerm, memberId } = payload
  let url = `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/attached-to-manager?manager_id=${memberId}&page_number=${pageNumber}`

  if (pageSize !== 'All') url += `&page_size=${pageSize}`
  if (order) url += `&order=${order}`
  if (searchTerm) url += `&search_term=${searchTerm}`

  const {
    data: { data },
  } = await axiosInstance.get(url)

  return data
}
export const getUsersToAttachList = async (payload) => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}${MEMBERS_API}/users-to-attach/list`
  )

  return data
}
export const attachUser = async (payload) => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}${MEMBERS_API}/assign-to-manager/${payload.managerId}/${payload.id}`
  )
}

export const updateMember = async (payload) => {
  const { member_id, member_update } = payload

  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/update/${member_id}`,
    { member_update }
  )
}

export const inviteUser = async (member_email) => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/invite`,
    { member_email }
  )
}

export const createMember = async (member) => {
  const {
    data: { data },
  } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/create`,
    member
  )

  return data
}

export const importMembers = async (requestData) => {
  const requestConfig = {
    headers: {
      'Contet-Type': 'multipart/form-data',
    },
  }

  const {
    data: { message },
  } = await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/import`,
    requestData,
    requestConfig
  )

  return message
}

export const exportMembers = async () => {
  const {
    data: {
      data: { members_export },
    },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/export`
  )

  return members_export
}

export const deleteUsers = async (payload) => {
  const { ids } = payload

  await axiosInstance.delete(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/delete/${ids}`
  )
}

export const assignSalesRole = async (payload) => {
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/assign/${payload.id}`
  )
}

export const unassignSalesRole = async (payload) => {
  await axiosInstance.patch(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/unassign/${payload}`
  )
}

export const getAttachedMembersList = async (memberId) => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}${MEMBERS_API}/attached-to-manager/?manager_id=${memberId}`
  )

  return data
}

export const unassignUserFromManager = async (payload) => {
  await axiosInstance.post(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}${MEMBERS_API}/unassign-from-manager/${payload.managerId}/${payload.memberId}`
  )
}

export const getB2BMembers = async () => {
  const {
    data: { data },
  } = await axiosInstance.get(
    `${VUE_APP_SERVER_BASE_URL}${API_V1}${USERS_API}/members/b2b/list`
  )

  return data
}
